module.exports = {
    // Site domain. Do not include a trailing slash!
    siteUrl: `https://maxderungs.com`,
    replacePort: `443`,
    
    // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)
    postsPerPage: 1000, 

    // This allows an alternative site title for meta data for pages.
    siteTitleMeta: `Alternative site title`,

    // This allows an alternative site description for meta data for pages.
    siteDescriptionMeta: `Foot`, 

    // Change to the width of your default share image
    shareImageWidth: 1000,

    // Change to the height of your default share image
    shareImageHeight: 523, 

    // Used for App manifest e.g. Mobile Home Screen
    shortTitle: `Ghost`, 

    // Logo in /static dir used for SEO, RSS, and App manifest
    siteIcon: `favicon.png`, 

    // Used for Offline Manifest
    backgroundColor: `#e9e9e9`, 

    // Used for Offline Manifest
    themeColor: `#15171A`,
}
